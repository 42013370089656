@import "variables";
@import "service";

@font-face {
  font-family: 'iconfont';  /* project id 464030 */
  src: url('//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.eot');
  src: url('//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.woff') format('woff'),
  url('//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.svg#iconfont') format('svg');
}
.iconfont {
    font-family: "iconfont" !important;
    font-size: 13px;
    font-style: normal;
    z-indexwebkit-font-smoothing: antialiased;
    z-indexmoz-osx-font-smoothing: grayscale;
}
body {
    font-family: "Candara","SF Pro Display","Arial";
    color: #666;
    font-size: 14px;
}
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
}
.clearfix {
    zoom: 1;
}
ul,ol,li {
    list-style-type:none;
    padding: 0;
}
a {
    color: #999;
    text-decoration: none;
}
a:hover, a:active, a:focus {
    color: #f90;
    text-decoration: none;
    outline: none;
}
.autosize {
    width: auto !important;    
}
.len-full {
    width: 100% !important;
}
.max-img-100 {
    max-width: 100%;
    height: auto;
}
$lengths: (len: 320px, len50: 50px, len80: 80px, len100: 100px, len150: 150px, len200: 200px, len500: 500px);
@each $lens, $size in $lengths {
  .#{$lens} {
      width: $size !important;
  }
}
$paddings: (pl-0: 0,pl-5: 5px, pl-7: 7px, pl-10: 10px, pl-15: 15px, pl-20: 20px, pl-119: 119px, pl-139: 139px, pl-149: 149px);
@each $pads, $px in $paddings {
  .#{$pads} {
      padding-left: $px !important;
  }
}
$paddrights: (pr-0: 0,pr-5: 5px, pr-7: 7px, pr-10: 10px, pr-15: 15px, pr-20: 20px);
@each $rights, $px in $paddrights {
  .#{$rights} {
      padding-right: $px !important;
  }
}
$paddtops: (pt-3: 3px, pt-5: 5px, pt-7: 7px, pt-10: 10px, pt-15: 15px, pt-20: 20px, pt-30: 30px, pt-40: 40px, pt-50: 50px, pt-60: 60px, pt-70: 70px, pt-80: 80px, pt-100: 100px);
@each $padts, $px in $paddtops {
  .#{$padts} {
      padding-top: $px !important;
  }
}
$paddbottoms: (pb-3: 3px, pb-5: 5px, pb-7: 7px, pb-10: 10px, pb-15: 15px, pb-20: 20px, pb-30: 30px, pb-40: 40px, pb-50: 50px, pb-100: 100px);
@each $padbs, $px in $paddbottoms {
  .#{$padbs} {
      padding-bottom: $px !important;
  }
}
$fontsizes: (font-3: 3px, font-5: 5px, font-7: 7px, font-10: 10px, font-12: 12px, font-13: 13px, font-14: 14px, font-15: 15px, font-20: 20px, font-50: 50px, font-100: 100px);
@each $fonts, $px in $fontsizes {
  .#{$fonts} {
      font-size: $px !important;
  }
}
$margintops: (mt-3: 3px, mt-5: 5px, mt-7: 7px, mt-10: 10px, mt-15: 15px, mt-20: 20px, mt-30: 30px, mt-40: 40px, mt-50: 50px);
@each $mtops, $px in $margintops {
  .#{$mtops} {
      margin-top: $px !important;
  }
}
$marginbottoms: (mb-3: 3px, mb-5: 5px, mb-7: 7px, mb-10: 10px, mb-15: 15px, mb-20: 20px, mb-30: 30px, mb-40: 40px);
@each $mbs, $px in $marginbottoms {
  .#{$mbs} {
      margin-bottom: $px !important;
  }
}
$marginlefts: (ml-5: 5px, ml-7: 7px, ml-10: 10px, ml-15: 15px, ml-20: 20px);
@each $lefts, $px in $marginlefts {
  .#{$lefts} {
      margin-left: $px !important;
  }
}
$marginrights: (mr-5: 5px, mr-7: 7px, mr-10: 10px, mr-15: 15px, mr-20: 20px);
@each $rights, $px in $marginrights {
  .#{$rights} {
      margin-right: $px !important;
  }
}
a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
  border-color: #3daf3e;
}

/* index ui style start */
#header {
    p {
      margin: 0;
    }
    .header-top {
        background: #1f1f1f;
        color: #dad8d8;
        font-size: 14px;
        .container {
            position: relative;
        }
        .contact {
            line-height: 22px;
            padding: 6px 70px 0 6px;
        }
        .feedback {
            text-align: right;
            padding-right: 80px;
            line-height: 34px;
            > a {
                background: url('../images/message.png') 0 50% no-repeat;
                color: #dad8d8;
                padding-left: 26px;
                display: inline-block;
                &:hover {
                    color: #fff;
                }
                &.site-map-top{
                    background: url('../images/sitemap.png') 0 50% no-repeat;
                    background-size: 21px auto;
                }
            }

        }
        .language-group {
            position: absolute;
            right: 5px;
            top: 0;
            .dropdown-toggle {
                color: #fff;
                background: none;
                &:hover {
                    text-decoration: none;
                }
            }
            .dropdown-menu {
                min-width: 100px;
                li {
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .navbar-header {
        .navbar-toggle {
            margin-top: 17px;
            border: 1px solid #40ad40 !important;
            .icon-bar {
                background-color: #40ad40;
            }
        }
        .navbar-brand {
            height: auto;
        }
    }
    .navbar-collapse {
        .navbar-nav {
            &.nav-style {
                padding-top: 12px;
            }
            li {
                a {
                    font-size: 18px;
                    background: url('../images/nav_right.png') right 50% no-repeat;
                    color: #666;
                    padding-left: 26px;
                    display: inline-block;
                    &:hover {
                        color: #4ca644;
                    }
                }
                &:last-child a {
                    background: none;
                }
            }
        }
    }
}

#carousel-example-generic {
    .carousel-indicators {
        bottom: 0;
        z-index: 999;
        li {
            @include border-radius(0);
            width: 25px;
            height: 5px;
            margin: 0 5px;
        }
    }
    .carousel-inner {
        z-index: 9;
        .item {
            img {
                width: 100%;
            }
            .carousel-cont {
                position: absolute;
                right: 19%;
                top: 30%;
                z-index: 99;
                @include opacity(0);
                @include transition(all 0.6s);
                color: #fff;
                .title {
                    font-size: 66px;
                }
                .info {
                    font-size: 45px;
                }
                .link {
                    width: 132px;
                    line-height: 52px;
                    display: inline-block;
                    background: #111;
                    color: #fff;
                    margin-top: 15px;
                }
            }
            &.active {
                .carousel-cont {
                    right: 20%;
                    top: 25%;
                    @include opacity(1);
                }
            }
        }
    }
}

.index-product-cate {
    padding: 25px 0;
    .first {
        background-size: cover !important;
        height: 366px;
        margin: 15px 0;
        padding: 20px 15px;
        overflow: hidden;
        position: relative;
        h3 {
            position: relative;
            width: 70%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 36px;
            height: 72px;
            padding-bottom: 40px;
            margin-top: 90px;
            @include transition(all 0.6s);
            a {
                color: #fff;
                font-size: 32px;
                &:hover {
                   color: #dbd9d9;
                }
            }
        }
        .common-bg {
            height: 40px;
        }
        .bg0 {
            background: url('../images/icon_1.png') 0 0 no-repeat;
        }
        .bg1 {
            background: url('../images/icon_2.png') 0 0 no-repeat;
        }
        .bg2 {
            background: url('../images/icon_3.png') 0 0 no-repeat;
        }
        .content {
            @include opacity(0);
            @include transition(all 0.6s);
            height: 0;
            overflow: hidden;
            .two {
                li {
                    background: url('../images/cate_icon.png') 0 50% no-repeat;
                    padding-left: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 28px;
                    a {
                        color: #fff;
                        font-size: 13px;
                        &:hover {
                            color: #dbd9d9;
                         }
                    }
                }
            }
        }
        .more {
            width: 94px;
            line-height: 34px;
            display: inline-block;
            background: #111;
            color: #fff;
            &:hover {
                background: #333;
            }
        }
        &:hover {
            h3 {
                margin-top: 20px;
            }
            .common-bg {
                display: none;
            }
            .content {
                height: auto;
                @include opacity(1);
            }
        }
    }
}

.product-content {
    background: #f5f5f5;
    padding-bottom: 50px; 
    .title {
        line-height: 130px;
        color: #000;
        font-size: 40px;
    }
    .content {
        .list {
            border: 1px solid #e1e6ea;
            background: #fff;
            margin: 10px 0;
            @include transition(all 0.6s);
            .img {
                position: relative;
                display: block;
                img {
                    width: 100%;
                }
                .left-tip {
                    position: absolute;
                    left: 10px;
                    top: 6px;
                    display: inline-block;
                    width: 50px;
                    line-height: 28px;
                    color: #FFF;
                    background: #5e8d25;
                    @include border-radius(2px);
                }
            }
            .cont {
                position: relative;
                height: 100px;
                .product-title {
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 96%;
                    line-height: 40px;
                    position: absolute;
                    left: 2%;
                    top: 30px;
                    @include transition(all 0.6s);
                }
                .more {
                    width: 132px;
                    line-height: 32px;
                    position: absolute;
                    left: 50%;
                    margin-left: -62px;
                    top: 20px;
                    display: inline-block;
                    background: #5e8d25;
                    color: #fff;
                    @include border-radius(2px);
                    &:hover {
                        background: #6db319;
                    }
                    @include opacity(0);
                    @include transition(all 0.6s);
                }
            }
            &:hover {
                box-shadow: 3px 3px 15px #cfcfcf;
                .product-title {
                    top: 8px;
                }
                .more {
                    top: 50px;
                    @include opacity(1);
                }
            }
        }
    }
    .product-more a {
        width: 260px;
        line-height: 52px;
        background: #121413;
        color: #fff;
        display: inline-block;
        margin-top: 25px;
        font-size: 18px;
        &:hover {
            background: #323634;
        }
    }
}

.bg-fixed {
    height: 500px;
    .content {
        padding-top: 110px;
        color: #FFF;
        .title {
            font-size: 73px;
            line-height: 73px;
        }
        .summary {
            font-size: 56px;
        }
        .more {
            width: 180px;
            line-height: 52px;
            background: #121413;
            color: #fff;
            display: inline-block;
            margin-top: 25px;
            font-size: 18px;
            &:hover {
                background: #323634;
            }
        }
    }
}

.index-news {
    background: #f5f5f5;
    padding: 15px 0 35px;
    .title {
        text-align: center;
        font-size: 40px;
        color: #000;
        line-height: 90px;
    }
    .index-news-list {
        li {
            margin: 8px 0;
            .img {
                display: block;
                img {
                    width: 100%;
                }
            }
            h3 {
                line-height: 28px;
                min-height: 52px;
                a {
                    color: #000;
                    &:hover {
                        color: #5e8d25;
                    }
                }
            }
            .text {
                font-size: 16px;
                color: #666;
                line-height: 24px;
            }
            .time {
                font-size: 16px;
                color: #5e8d25;
                line-height: 34px;
            }
        }
    }
}

.footer {
	background: #292929;
    display: block;
    .foot-top {
        padding: 70px 0;
        .about-us {
            border-right: 1px solid #3f3f3f;
            .logo {
                background: url('../images/foot_logo.png') 0 0 no-repeat;
                height: 39px;
            }
            .content {
                margin-top: 40px;
                color: #bdbdbd;
                line-height: 26px;
                font-size: 15px;
                p {
                    margin: 0;
                }
            }
        }
        .contact-us {
            padding: 22px 20px 0;
            .tel {
                min-width: 120px;
                margin-right: 20px;
            }
            .tel .title {
                background: url('../images/tel.png') 0 50% no-repeat;
            }
            .email .title {
                background: url('../images/email.png') 0 50% no-repeat;
            }
            .address .title {
                background: url('../images/map.png') 0 50% no-repeat;
            }
            .address {
                margin-top: 10px;
            }
            .title {
                color: #979797;
                line-height: 34px;
                padding-left: 34px;
                font-size: 15px;
            }
            .content {
                color: #fff;
                line-height: 24px;
                padding: 5px 0;
            }
        }
        .search {
            margin-top: 20px;
            max-width: 330px;
            .input-group {
                vertical-align: top;
                .keyword {
                    height: 40px;
                    line-height: 40px;
                    border: none;
                }
                .btn-default {
                    width: 40px;
                    height: 40px;
                    background: url('../images/search.png') 50% 50% no-repeat #5e8d25;
                    border: none;
                }
            }
        }
    }
	
}

.foot-bottom {
    background: #1f1f1f;
    .content {
		padding: 12px 0;
        line-height: 32px;
        .copyright {
            color: #bcbcbc;
        }
		.share-foot {
			text-align: right;
			margin-top: 0;
			a {
				border: none;
                margin: 0 4px;
                &:hover {
                    background: #5e8d25;
                    color: #fff;
                }
			}
		}
		a {
			color: #fff;
		}
		a:hover {
			color: #949494;
		}
	}
}



@include min-width(768px) {
    .navbar-nav {
        float: right;
        li > a {
          padding-left: 20px;
          padding-right: 20px;
        }
    }
}

@include max-screen(768px) {
    .navbar-nav {
        &.nav-style {
            padding-top: 0 !important;
        }
        li {
            border-bottom: 1px solid #eee;
            a {
                background: none !important;
                display: block !important;
            }
        }
    }
    #carousel-example-generic .carousel-inner .item {
        .carousel-cont {
            right: 20%;
            top: 12%;
            @include opacity(1);
        }
        &.active {
            .carousel-cont {
                right: 20%;
                top: 12%;
            }
        }
    }
    .product-content .title {
        font-size: 30px;
    }
    .index-product-cate .first {
        h3 {
            margin-top: 20px;
        }
        .common-bg {
            display: none;
        }
        .content {
            height: auto;
            @include opacity(1);
        }
    }
    #page-wrap .jqzoom {
        width: 100%;
        height: auto;
        .small {
            width: 100%;
            height: auto;
        }
    }
}

@include max-screen(992px) {
    #carousel-example-generic .carousel-inner .item .carousel-cont {
        .title {
            font-size: 24px;
        }
        .info {
            font-size: 16px;
        }
        .link {
            width: 82px;
            line-height: 26px;
        }
    }
    .footer .foot-top .about-us {
        border-right: none;
    }
}


.video-detail {
    .detail-list-item {
        line-height: 32px;
        font-size: 15px;
    }
    .detail-title {
        border-bottom: 1px solid #f0f0f0;
        line-height: 50px;
    }
    .video-content {
        padding: 10px 0;
    }
}

.job-list {
    li {
        position: relative;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 15px;
        h3 {
            font-size: 16px;
            a {
                color: #000;
                &:hover {
                    color: $a-hover-color;
                }
            }
        }
        .time {
            font-size: 14px;
        }
    }
}

.job-detail {
    .title {
        border-bottom: 1px solid #f0f0f0;
        font-size: 18px;
        line-height: 32px;
        padding: 7px 0;
    }
    .job-head {
        border-bottom: 1px solid #f0f0f0;
        span {
            display: inline-block;
            margin-right: 10px;
            font-size: 14px;
        }
    }
    .light {
        line-height: 40px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 16px;
    }
    .cont {
        line-height: 26px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 14px;
        padding: 7px 0;
    }
}

.download-list {
    li {
        position: relative;
        line-height: 34px;
        border-bottom: 1px solid #f0f0f0;
        h3 {
            font-size: 16px;
            margin: 0;
            line-height: 34px;
            padding-right: 110px;
            a {
                color: #000;
                display: inline-block;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                &:hover {
                    color: $a-hover-color;
                }
            }
        }
        .time {
            position: absolute;
            font-size: 14px;
            right: 0;
            top: 0;
        }
    }
}

.message-product {
    img {
        width: 100%;
        height: auto;
        display: block;
        border: 1px solid #eee;
    }
    .message-product-title {
        font-size: 18px;
        color: #000;
        line-height: 36px;
    }
    .message-product-summary {
        font-size: 14px;
        line-height: 20px;
    }
    .message-product-href {
        background-color: #ff9966;
        color: #fff;
        font-size: 16px;
        border-radius: 3px;
        transition: all 0.3s ease 0s;
        padding: 0 10px;
        line-height: 34px;
        display: inline-block;
        &:hover {
            background: #ff982d;
        }
    }
}

.product-detail {
    .product-download {
        li {
            min-height: 40px;
            position: relative;
            margin-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f0f0f0;
            .iconfont {
                font-size: 40px;
                position: absolute;
                left: 0;
                top: 10px;
            }
            .cont {
                p {
                    margin: 0;
                    font-size: 15px;
                    color: #000;
                }
                padding-left: 50px;
                line-height: 20px;
            }
        }
    }
}

.download-detail {
    .title {
        font-size: 22px;
        line-height: 32px;
    }
    .detail-title {
        font-size: 16px;
        border-bottom: 1px solid #f0f0f0;
        line-height: 40px;
    }
    .download-content {
        line-height: 24px;
        padding: 10px 0;
    }
    .download-btn {
        text-align: center;
        line-height: 34px;
        .download-icon {
            .iconfont {
                font-size: 40px;
            }
        }
        a:hover {
            color: $a-hover-color;
        }
    }
}

/* index ui style end */

/*inner page style*/
#page-wrap {
    padding-bottom: 30px;
}
#anchor {
    padding: 18px 0;
    line-height: 24px;
    background-color: #f5f6f9;
    font-size: 16px;
    color: #666;
    font-family: "Arial";
    .anchor {
        color: #666;
        a {
            color: #666;
            &:visited {
                text-decoration: none;
                color: #666;
            }
            &:hover {
                text-decoration: none;
                color: #41ad3d;
            }
        }
    }
}
.detail-title {
    border-bottom: 1px solid $border-color-default;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 0;
}
.left-title {
    font-weight: bold;
    color: #333;
    font-size: 26px;
    padding: 15px 0;
}
.cate-list {
    overflow: hidden;
    border-left: 1px solid $border-color-default;
    border-right: 1px solid $border-color-default;
    border-bottom: none;
    .list-title {
        line-height: 60px;
        display: block;
        padding-left: 15px;
        background: url(../images/arrow_cate02.jpg) no-repeat 95% 50%;
        color: #444;
        font-weight: bold;
        font-size: 18px;
        border-top: 1px solid $border-color-default;
        border-bottom: 1px solid $border-color-default;
        transition: all 0.3s ease 0s;
        padding-right: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.arrow-click {
            background: url(../images/arrow_cate01.jpg) no-repeat 95% 50%;
        }
        &.arrow-03 {
            background: url(../images/arrow_cate03.jpg) no-repeat 95% 50%;
        }
        &:hover {
            color: #41ad3d;
        }
    }
    .map-category-list {
        display: none;
        padding: 10px;
        line-height: 24px;
        &.show {
            display: block;
        }
    }
    .active {
        color: #41ad3d;
    }
}
.right {
    .content {
        border: 1px solid $border-color-default;
        padding: 10px;
        line-height: 22px;
        font-size: 14px;
        img {
            max-width: 100% !important;
            height: auto !important;
        }
        &.no-border {
            border: none;
        }
    }
}
.news-lists {
    overflow: hidden;
    li {
        border: 1px solid $border-color-default;
        padding: 15px 25px;
        h3 {
            font-size: 22px;
            a {
                color: #000;
                transition: all 0.3s ease 0s;
                &:visited {
                    color: #000;
                    text-decoration: none;
                }
                &:hover {
                    text-decoration: none;
                    color: #41ad3d;
                }
            }
        }
        .time {
            font-size: 13px;
            color: #333;
        }
        .news-abstract {
            font-size: 14px;
            color: #333;
            line-height: 24px;
        }
    }
}
.news-detail {
    border: 1px solid $border-color-default;
    padding: 15px;
    .title {
        border-bottom: 1px solid $border-color-default;
        font-size: 22px;
        color: #000;
        .time-text {
            padding: 10px 0;
            font-size: 14px;
            color: #333;
        }
    }
}
.edge {
    height: 1px;
    background: $border-color-default;
}
.news-list-relat {
    overflow: hidden;
    li {
        line-height: 50px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        a {
            transition: all 0.3s ease 0s;
            color: #333;
            font-size: 14px;
            display: inline-block;
            line-height: 24px;
            &:visited {
                text-decoration: none;
                color: #333;
            }
            &:hover {
                text-decoration: none;
                color: #41ad3d;
            }
        }
        .time {
            display: inline-block;
            line-height: 24px;
        }
    }
}
.tags-list-detail {
    border-bottom: 1px dashed $border-color-default;
    padding: 10px 0;
    a {
        padding-right: 5px;
    }
}
#page-next-prev {
    padding-top: 6px;
    li {
        padding: 6px 0;
    }
}

.product-list {
    .title {
        line-height: 130px;
        color: #000;
        font-size: 40px;
    }
    .content {
        .list {
            border: 1px solid #e1e6ea;
            background: #fff;
            margin: 10px 0;
            @include transition(all 0.6s);
            .img {
                position: relative;
                display: block;
                img {
                    width: 100%;
                }
                .left-tip {
                    position: absolute;
                    left: 10px;
                    top: 6px;
                    display: inline-block;
                    width: 50px;
                    line-height: 28px;
                    color: #FFF;
                    background: #5e8d25;
                    @include border-radius(2px);
                }
            }
            .cont {
                position: relative;
                height: 100px;
                .product-title {
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 96%;
                    line-height: 40px;
                    position: absolute;
                    left: 2%;
                    top: 30px;
                    font-size: 18px;
                    @include transition(all 0.6s);
                }
                .more {
                    width: 132px;
                    line-height: 32px;
                    position: absolute;
                    left: 50%;
                    margin-left: -62px;
                    top: 20px;
                    display: inline-block;
                    background: #5e8d25;
                    color: #fff;
                    @include border-radius(2px);
                    &:hover {
                        background: #6db319;
                    }
                    @include opacity(0);
                    @include transition(all 0.6s);
                }
            }
            &:hover {
                box-shadow: 3px 3px 15px #cfcfcf;
                .product-title {
                    top: 8px;
                }
                .more {
                    top: 50px;
                    @include opacity(1);
                }
            }
        }
    }
}
.case-list {
    .title {
        line-height: 130px;
        color: #000;
        font-size: 40px;
    }
    .content {
        .list {
            border: 1px solid #e1e6ea;
            background: #fff;
            margin: 10px 0;
            @include transition(all 0.6s);
            .img {
                position: relative;
                display: block;
                img {
                    width: 100%;
                }
                .left-tip {
                    position: absolute;
                    left: 10px;
                    top: 6px;
                    display: inline-block;
                    width: 50px;
                    line-height: 28px;
                    color: #FFF;
                    background: #5e8d25;
                    @include border-radius(2px);
                }
            }
            .cont {
                position: relative;
                height: 100px;
                .case-title {
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 96%;
                    line-height: 40px;
                    position: absolute;
                    left: 2%;
                    top: 30px;
                    @include transition(all 0.6s);
                }
                .more {
                    width: 132px;
                    line-height: 32px;
                    position: absolute;
                    left: 50%;
                    margin-left: -62px;
                    top: 20px;
                    display: inline-block;
                    background: #5e8d25;
                    color: #fff;
                    @include border-radius(2px);
                    &:hover {
                        background: #6db319;
                    }
                    @include opacity(0);
                    @include transition(all 0.6s);
                }
            }
            &:hover {
                box-shadow: 3px 3px 15px #cfcfcf;
                .case-title {
                    top: 8px;
                }
                .more {
                    top: 50px;
                    @include opacity(1);
                }
            }
        }
    }
}
.case-detail {
    overflow: hidden;
    .title {
        border-bottom: 1px solid $border-color-default;
        text-align: center;
        font-size: 26px;
        margin: 0;
        padding: 15px 0;
        font-weight: bold;
        color: #666;
    }
    .case-imgs {
        overflow: hidden;
        a.auto {
            overflow: hidden;
            text-align: center;
            width: auto;
            .zoomPad {
                float: none;
            }
        }
    }
}
.page-content {
    border-top: 1px dashed $border-color-default;
    .page-left {
        display: inline-block;
        padding-top: 24px;
    }
}
.jqzoom {
    text-decoration: none;
    display: block;
    width: 400px;
    height: 400px;
    margin: 0 auto;
    margin-bottom: 8px;
    &.auto {
        height: auto !important;
    }
    .small {
        width: auto;
        height: 400px;
    }
}
.detail-list-item {
    h1 {
        font-size: 26px;
        color: #000;
    }
    .abstract {
        line-height: 24px;
        border-bottom: 1px solid $border-color-default;
        font-size: 16px;
    }
    li {
        padding: 10px 0;
        font-size: 16px;
        .dt {
            display: inline;
            font-weight: bold;
            color: #000;
        }
        .dd {
            display: inline;
        }
    }
}
.inquiry {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 50px;
    color: #fff;
    background: linear-gradient(180deg,#383838 0,#000000);
    border-radius: 3px;
    font-size: 16px;
    transition: all 0.3s ease 0s;
    &:visited {
        color: #fff;
    }
    &:hover {
        color: #fff;
        background: linear-gradient(180deg,#f90 0,#dc8503);
    }
}
.tab-pane {
    padding: 15px 10px;
    line-height: 22px;
    font-size: 14px;
}

.nav-tabs {
    border-color: #ddd;
    li {
        margin-left: -1px;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        z-index: 1;
        a {
            color: #666;
            border-left: 0px;
            border-right: 0px;
            margin-right: 0px;
            padding: 10px 16px;
            background: #FBFAF8;
            border-bottom: 0px;
            border-radius: 0;
            font-weight: bold;
            font-size: 16px;
            &:focus {
                color: #666;
                border-left: 0px;
                border-right: 0px;
                margin-right: 0px;
                padding: 10px 16px;
                background: #FBFAF8;
                border-bottom: 0px;
            }
            &:hover {
                background-color: #FFF;
                color: #5e8d25;
            }
        }
        &.active {
            border-top: 0px;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            z-index: 3;
            a {
                border-top: 2px solid #5e8d25;
                border-left: 0px;
                border-right: 0px;
                border-bottom: 1px solid #FFF;
                color: #5e8d25;
                &:hover, &:focus {
                    border-top: 2px solid #5e8d25;
                    border-left: 0px;
                    border-right: 0px;
                    border-bottom: 1px solid #FFF;
                    color: #5e8d25;
                }
            }
        }
    }
}
.no-information {
    padding: 20px 0;
}
.search-menu {
	border: 1px solid #e5e5e5;
	li {
		text-align: center;
		line-height: 34px;
		a {
			color: #333;
		}
	}
	.on {
		a {
			color: #41ad3d;
		}
	}
}
.search-list {
	padding-top: 10px;
	li {
		border-bottom: 1px dotted #e5e5e5;
		position: relative;
		padding: 0;
		margin: 0;
		h3 {
			margin: 0;
			line-height: 36px;
			a {
				font-size: 14px;
				color: #333;
				b {
					font-weight: normal;
					padding-right: 5px;
				}
			}
			a:hover {
				color: #41ad3d;
			}
		}
		.time {
			position: absolute;
			right: 0;
			top: 13px;
		}
	}
}

.search-img-list {
	padding-top: 10px;
	li {
		margin-bottom: 12px;
		.img {
			img {
				width: 100%;
				height: 100%;
			}
		}
		h3 {
			text-align: center;
			margin: 0;
			line-height: 34px;
			a {
				font-size: 13px;
				color: #333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
			}
			a:hover {
				color: #41ad3d;
			}
		}
	}
}
.sitemp-content {
	padding: 10px 0 20px;
	.map-title {
		line-height: 34px;
	    background-color: #f5f8fa;
	    border: 1px solid #d6dbe1;
	    text-indent: 20px;
	    color: #666;
	    border-bottom: 2px solid #cccccc;
	    font-size: 14px;
	}
    .map-list {
        a {
            padding-right: 8px;
        }
        .map-product-cate-cname {
            font-size: 15px;
            color: #666;
            border-bottom: 1px dashed #cccccc;
            padding-bottom: 15px;
            a {
                color: #666;
                &:visited {
                    text-decoration: none;
                    color: #666;
                }
                &:hover {
                    text-decoration: none;
                    color: #1890ff;
                }
            }
        }
        .map-product-list {
            li {
                padding-top: 8px;
                padding-bottom: 8px;
                a {
                    font-size: 14px;
                    color: #999;
                    &:visited {
                        text-decoration: none;
                        color: #999;
                    }
                    &:hover {
                        text-decoration: none;
                        color: #1890ff;
                    }
                }
            } 
        }
    }
    .sitemp-about-us {
        font-size: 18px;
        font-weight: bold;
    }
    .sitemp-about-content {
        border: 1px solid #d6dbe1;
        padding: 10px;
        line-height: 24px;
    }
}
.tag-all {
    a {
        background-color: #f2f2f2;
        color: #666;
        padding: 5px 8px;
        text-decoration: none;
        margin-right: 5px;
        margin-bottom: 10px;
        display: inline-block;
        &:hover {
            background-color: #ddd;
        }
    }
}
.tag-menu {
    li {
        text-align: center;
        &.active a {
            color: #1890ff;
            font-weight: bold;
        }
    }
}
.tag-item,.sitemp-item {
    padding: 15px 0;
    font-size: 18px;
    border-bottom: 1px solid #d6dbe1;
}
.tag-describe {
    padding: 15px;
    border: 1px solid #e1e1e1;
    font-size: 16px;
    line-height: 24px;
    span {
        color: #d8012d;
        font-weight: bold;
    }
}
.tag-view {
    line-height: 24px;
    font-size: 16px;
    color: #7b7b7b;
    font-family: Verdana, Geneva, sans-serif;
    a {
        i {
            font-size: 18px;
            color: #9b6a99;
        }
        &:hover i {
            color: #666;
        }
        &.active i {
            color: #f90;
        }
    } 

}
.tag-category {
    span {
        font-weight: bold;
        color: #7b7b7b;
        font-size: 12px;
        font-family: Verdana, Geneva, sans-serif;
    }
    a {
        display: inline-block;
        padding: 5px 6px;
        border: 1px solid $border-color-default;
        margin: 0 6px 10px 6px;
        border-radius: 4px;
    }

}
.ishide {
    display: none;
}
.tag-product-list {
    overflow: hidden;
    li {
        border-bottom: 1px dashed #d6dbe1;
        padding: 20px 0;
        &:last-child {
            border: none;
        }
        .img {
            display: block;
            overflow: hidden;
            img {
                width: 100%;
                padding: 4px;
                border-radius: 3px;
                border: 1px solid #e1e6ea;
                transition: all 0.3s ease 0s;
                &:hover {
                    border: 1px solid #3daf3e;
                }
            }
        }
        .tag-product-content {
            .title {
                font-size: 20px;
                color: #555;
                font-weight: bold;
                transition: all 0.3s ease 0s;
                padding-bottom: 10px;
                &:hover {
                    color: #f90;
                }
            }
            .attribute {
                overflow: hidden;
                padding-bottom: 10px;
            }
            .abstract {
                div {
                    border: 1px solid $border-color-default;
                    padding: 10px;
                    line-height: 24px;
                }

            }
            .tag-link {
                .send-inquiry {
                    color: #fff;
                    padding: 10px 20px;
                    font-size: 16px;
                    background-color: #ff6666;
                    border-radius: 3px;
                    transition: all 0.3s ease 0s;
                    &.detail {
                        background-color: #ff9966;
                    }
                    &:hover {
                        color: #fff;
                        background-color: #f90;
                    }
                }
            }
        }
    }
}
.tag-statis {
    background-color: #f5f5f5;
    border: 1px solid $border-color-default;
    padding: 10px 15px;
    margin-top: 10px;
    span {
        color: #333;

    }
}
.tag-title {
    text-align: center;
    font-size: 14px;
}
.attr-list {
    border-top: 1px solid #f0f0f0;
    .attr-list-content {
        border-bottom: 1px solid #f0f0f0;
        padding: 10px 0;
        line-height: 24px;
        font-size: 16px;
        .attr-title {
            display: inline-block;
        }
    }
}
#dialog-common {
	.btn-primary {
		background-color: #63b75e;
    	border-color: #63b75e;
	}
	.modal-body {
		.fa-exclamation-circle {
			font-size: 34px;
			color: #e87518;
		}
		.common {
			padding-top: 5px;
			font-size: 15px;
		}
		.fa-check-circle {
			font-size: 34px;
			color: #33cc1d;
		}
	}
}
.tab-content {
    .key-tag {
        color: #ff0000;
        &:visited {
            text-decoration: none;
            color: #ff0000;
        }
        &:hover {
            text-decoration: none;
            color: #f90;
        }
    }
}
/*inner page style*/