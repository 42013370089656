.service-view {
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 99999;
}

.service-view .service-list {
  background: rgba(134, 190, 242, 0.2);
  padding: 6px 6px 2px;
  border-radius: 4px;
}

.service-view .service-list li {
  margin-bottom: 5px;
  border-radius: 8px;
  padding: 5px;
}

.service-view .service-list li a img {
  width: 40px;
  height: 40px;
}

.service-view .service-list li.skype {
  background: #00adec;
}

.service-view .service-list li.whatsapp {
  background: #179d1a;
}

.service-view .service-list li.line {
  background: #00b906;
}

.service-view .service-list li.email {
  background: #f26635;
}

.service-view .service-list li.message {
  background: #ad88be;
}

.service-view .service-list li:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.service-view .service-close {
  position: absolute;
  right: -6px;
  top: -15px;
  width: 20px;
  height: 20px;
  background: #fff;
  color: #222;
  border-radius: 10px;
  line-height: 18px;
  border: 1px solid #ddd;
  font-family: "Arial";
  z-index: 99;
  cursor: pointer;
}

@font-face {
  font-family: 'iconfont';
  /* project id 464030 */
  src: url("//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.eot");
  src: url("//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.woff") format("woff"), url("//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.ttf") format("truetype"), url("//at.alicdn.com/t/font_464030_hmu5yynw4h9nqaor.svg#iconfont") format("svg");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 13px;
  font-style: normal;
  z-indexwebkit-font-smoothing: antialiased;
  z-indexmoz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Candara","SF Pro Display","Arial";
  color: #666;
  font-size: 14px;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

ul, ol, li {
  list-style-type: none;
  padding: 0;
}

a {
  color: #999;
  text-decoration: none;
}

a:hover, a:active, a:focus {
  color: #f90;
  text-decoration: none;
  outline: none;
}

.autosize {
  width: auto !important;
}

.len-full {
  width: 100% !important;
}

.max-img-100 {
  max-width: 100%;
  height: auto;
}

.len {
  width: 320px !important;
}

.len50 {
  width: 50px !important;
}

.len80 {
  width: 80px !important;
}

.len100 {
  width: 100px !important;
}

.len150 {
  width: 150px !important;
}

.len200 {
  width: 200px !important;
}

.len500 {
  width: 500px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-119 {
  padding-left: 119px !important;
}

.pl-139 {
  padding-left: 139px !important;
}

.pl-149 {
  padding-left: 149px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-7 {
  padding-right: 7px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-7 {
  padding-bottom: 7px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.font-3 {
  font-size: 3px !important;
}

.font-5 {
  font-size: 5px !important;
}

.font-7 {
  font-size: 7px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-50 {
  font-size: 50px !important;
}

.font-100 {
  font-size: 100px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
  border-color: #3daf3e;
}

/* index ui style start */
#header p {
  margin: 0;
}

#header .header-top {
  background: #1f1f1f;
  color: #dad8d8;
  font-size: 14px;
}

#header .header-top .container {
  position: relative;
}

#header .header-top .contact {
  line-height: 22px;
  padding: 6px 70px 0 6px;
}

#header .header-top .feedback {
  text-align: right;
  padding-right: 80px;
  line-height: 34px;
}

#header .header-top .feedback > a {
  background: url("../images/message.png") 0 50% no-repeat;
  color: #dad8d8;
  padding-left: 26px;
  display: inline-block;
}

#header .header-top .feedback > a:hover {
  color: #fff;
}

#header .header-top .feedback > a.site-map-top {
  background: url("../images/sitemap.png") 0 50% no-repeat;
  background-size: 21px auto;
}

#header .header-top .language-group {
  position: absolute;
  right: 5px;
  top: 0;
}

#header .header-top .language-group .dropdown-toggle {
  color: #fff;
  background: none;
}

#header .header-top .language-group .dropdown-toggle:hover {
  text-decoration: none;
}

#header .header-top .language-group .dropdown-menu {
  min-width: 100px;
}

#header .header-top .language-group .dropdown-menu li a {
  text-decoration: none;
}

#header .navbar-header .navbar-toggle {
  margin-top: 17px;
  border: 1px solid #40ad40 !important;
}

#header .navbar-header .navbar-toggle .icon-bar {
  background-color: #40ad40;
}

#header .navbar-header .navbar-brand {
  height: auto;
}

#header .navbar-collapse .navbar-nav.nav-style {
  padding-top: 12px;
}

#header .navbar-collapse .navbar-nav li a {
  font-size: 18px;
  background: url("../images/nav_right.png") right 50% no-repeat;
  color: #666;
  padding-left: 26px;
  display: inline-block;
}

#header .navbar-collapse .navbar-nav li a:hover {
  color: #4ca644;
}

#header .navbar-collapse .navbar-nav li:last-child a {
  background: none;
}

#carousel-example-generic .carousel-indicators {
  bottom: 0;
  z-index: 999;
}

#carousel-example-generic .carousel-indicators li {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  width: 25px;
  height: 5px;
  margin: 0 5px;
}

#carousel-example-generic .carousel-inner {
  z-index: 9;
}

#carousel-example-generic .carousel-inner .item img {
  width: 100%;
}

#carousel-example-generic .carousel-inner .item .carousel-cont {
  position: absolute;
  right: 19%;
  top: 30%;
  z-index: 99;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  color: #fff;
}

#carousel-example-generic .carousel-inner .item .carousel-cont .title {
  font-size: 66px;
}

#carousel-example-generic .carousel-inner .item .carousel-cont .info {
  font-size: 45px;
}

#carousel-example-generic .carousel-inner .item .carousel-cont .link {
  width: 132px;
  line-height: 52px;
  display: inline-block;
  background: #111;
  color: #fff;
  margin-top: 15px;
}

#carousel-example-generic .carousel-inner .item.active .carousel-cont {
  right: 20%;
  top: 25%;
  opacity: 1;
  filter: alpha(opacity=100);
}

.index-product-cate {
  padding: 25px 0;
}

.index-product-cate .first {
  background-size: cover !important;
  height: 366px;
  margin: 15px 0;
  padding: 20px 15px;
  overflow: hidden;
  position: relative;
}

.index-product-cate .first h3 {
  position: relative;
  width: 70%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 36px;
  height: 72px;
  padding-bottom: 40px;
  margin-top: 90px;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.index-product-cate .first h3 a {
  color: #fff;
  font-size: 32px;
}

.index-product-cate .first h3 a:hover {
  color: #dbd9d9;
}

.index-product-cate .first .common-bg {
  height: 40px;
}

.index-product-cate .first .bg0 {
  background: url("../images/icon_1.png") 0 0 no-repeat;
}

.index-product-cate .first .bg1 {
  background: url("../images/icon_2.png") 0 0 no-repeat;
}

.index-product-cate .first .bg2 {
  background: url("../images/icon_3.png") 0 0 no-repeat;
}

.index-product-cate .first .content {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  height: 0;
  overflow: hidden;
}

.index-product-cate .first .content .two li {
  background: url("../images/cate_icon.png") 0 50% no-repeat;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 28px;
}

.index-product-cate .first .content .two li a {
  color: #fff;
  font-size: 13px;
}

.index-product-cate .first .content .two li a:hover {
  color: #dbd9d9;
}

.index-product-cate .first .more {
  width: 94px;
  line-height: 34px;
  display: inline-block;
  background: #111;
  color: #fff;
}

.index-product-cate .first .more:hover {
  background: #333;
}

.index-product-cate .first:hover h3 {
  margin-top: 20px;
}

.index-product-cate .first:hover .common-bg {
  display: none;
}

.index-product-cate .first:hover .content {
  height: auto;
  opacity: 1;
  filter: alpha(opacity=100);
}

.product-content {
  background: #f5f5f5;
  padding-bottom: 50px;
}

.product-content .title {
  line-height: 130px;
  color: #000;
  font-size: 40px;
}

.product-content .content .list {
  border: 1px solid #e1e6ea;
  background: #fff;
  margin: 10px 0;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.product-content .content .list .img {
  position: relative;
  display: block;
}

.product-content .content .list .img img {
  width: 100%;
}

.product-content .content .list .img .left-tip {
  position: absolute;
  left: 10px;
  top: 6px;
  display: inline-block;
  width: 50px;
  line-height: 28px;
  color: #FFF;
  background: #5e8d25;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.product-content .content .list .cont {
  position: relative;
  height: 100px;
}

.product-content .content .list .cont .product-title {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96%;
  line-height: 40px;
  position: absolute;
  left: 2%;
  top: 30px;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.product-content .content .list .cont .more {
  width: 132px;
  line-height: 32px;
  position: absolute;
  left: 50%;
  margin-left: -62px;
  top: 20px;
  display: inline-block;
  background: #5e8d25;
  color: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.product-content .content .list .cont .more:hover {
  background: #6db319;
}

.product-content .content .list:hover {
  box-shadow: 3px 3px 15px #cfcfcf;
}

.product-content .content .list:hover .product-title {
  top: 8px;
}

.product-content .content .list:hover .more {
  top: 50px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.product-content .product-more a {
  width: 260px;
  line-height: 52px;
  background: #121413;
  color: #fff;
  display: inline-block;
  margin-top: 25px;
  font-size: 18px;
}

.product-content .product-more a:hover {
  background: #323634;
}

.bg-fixed {
  height: 500px;
}

.bg-fixed .content {
  padding-top: 110px;
  color: #FFF;
}

.bg-fixed .content .title {
  font-size: 73px;
  line-height: 73px;
}

.bg-fixed .content .summary {
  font-size: 56px;
}

.bg-fixed .content .more {
  width: 180px;
  line-height: 52px;
  background: #121413;
  color: #fff;
  display: inline-block;
  margin-top: 25px;
  font-size: 18px;
}

.bg-fixed .content .more:hover {
  background: #323634;
}

.index-news {
  background: #f5f5f5;
  padding: 15px 0 35px;
}

.index-news .title {
  text-align: center;
  font-size: 40px;
  color: #000;
  line-height: 90px;
}

.index-news .index-news-list li {
  margin: 8px 0;
}

.index-news .index-news-list li .img {
  display: block;
}

.index-news .index-news-list li .img img {
  width: 100%;
}

.index-news .index-news-list li h3 {
  line-height: 28px;
  min-height: 52px;
}

.index-news .index-news-list li h3 a {
  color: #000;
}

.index-news .index-news-list li h3 a:hover {
  color: #5e8d25;
}

.index-news .index-news-list li .text {
  font-size: 16px;
  color: #666;
  line-height: 24px;
}

.index-news .index-news-list li .time {
  font-size: 16px;
  color: #5e8d25;
  line-height: 34px;
}

.footer {
  background: #292929;
  display: block;
}

.footer .foot-top {
  padding: 70px 0;
}

.footer .foot-top .about-us {
  border-right: 1px solid #3f3f3f;
}

.footer .foot-top .about-us .logo {
  background: url("../images/foot_logo.png") 0 0 no-repeat;
  height: 39px;
}

.footer .foot-top .about-us .content {
  margin-top: 40px;
  color: #bdbdbd;
  line-height: 26px;
  font-size: 15px;
}

.footer .foot-top .about-us .content p {
  margin: 0;
}

.footer .foot-top .contact-us {
  padding: 22px 20px 0;
}

.footer .foot-top .contact-us .tel {
  min-width: 120px;
  margin-right: 20px;
}

.footer .foot-top .contact-us .tel .title {
  background: url("../images/tel.png") 0 50% no-repeat;
}

.footer .foot-top .contact-us .email .title {
  background: url("../images/email.png") 0 50% no-repeat;
}

.footer .foot-top .contact-us .address .title {
  background: url("../images/map.png") 0 50% no-repeat;
}

.footer .foot-top .contact-us .address {
  margin-top: 10px;
}

.footer .foot-top .contact-us .title {
  color: #979797;
  line-height: 34px;
  padding-left: 34px;
  font-size: 15px;
}

.footer .foot-top .contact-us .content {
  color: #fff;
  line-height: 24px;
  padding: 5px 0;
}

.footer .foot-top .search {
  margin-top: 20px;
  max-width: 330px;
}

.footer .foot-top .search .input-group {
  vertical-align: top;
}

.footer .foot-top .search .input-group .keyword {
  height: 40px;
  line-height: 40px;
  border: none;
}

.footer .foot-top .search .input-group .btn-default {
  width: 40px;
  height: 40px;
  background: url("../images/search.png") 50% 50% no-repeat #5e8d25;
  border: none;
}

.foot-bottom {
  background: #1f1f1f;
}

.foot-bottom .content {
  padding: 12px 0;
  line-height: 32px;
}

.foot-bottom .content .copyright {
  color: #bcbcbc;
}

.foot-bottom .content .share-foot {
  text-align: right;
  margin-top: 0;
}

.foot-bottom .content .share-foot a {
  border: none;
  margin: 0 4px;
}

.foot-bottom .content .share-foot a:hover {
  background: #5e8d25;
  color: #fff;
}

.foot-bottom .content a {
  color: #fff;
}

.foot-bottom .content a:hover {
  color: #949494;
}

@media (min-width: 768px) {
  .navbar-nav {
    float: right;
  }
  .navbar-nav li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-nav.nav-style {
    padding-top: 0 !important;
  }
  .navbar-nav li {
    border-bottom: 1px solid #eee;
  }
  .navbar-nav li a {
    background: none !important;
    display: block !important;
  }
  #carousel-example-generic .carousel-inner .item .carousel-cont {
    right: 20%;
    top: 12%;
    opacity: 1;
    filter: alpha(opacity=100);
  }
  #carousel-example-generic .carousel-inner .item.active .carousel-cont {
    right: 20%;
    top: 12%;
  }
  .product-content .title {
    font-size: 30px;
  }
  .index-product-cate .first h3 {
    margin-top: 20px;
  }
  .index-product-cate .first .common-bg {
    display: none;
  }
  .index-product-cate .first .content {
    height: auto;
    opacity: 1;
    filter: alpha(opacity=100);
  }
  #page-wrap .jqzoom {
    width: 100%;
    height: auto;
  }
  #page-wrap .jqzoom .small {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 992px) {
  #carousel-example-generic .carousel-inner .item .carousel-cont .title {
    font-size: 24px;
  }
  #carousel-example-generic .carousel-inner .item .carousel-cont .info {
    font-size: 16px;
  }
  #carousel-example-generic .carousel-inner .item .carousel-cont .link {
    width: 82px;
    line-height: 26px;
  }
  .footer .foot-top .about-us {
    border-right: none;
  }
}

.video-detail .detail-list-item {
  line-height: 32px;
  font-size: 15px;
}

.video-detail .detail-title {
  border-bottom: 1px solid #f0f0f0;
  line-height: 50px;
}

.video-detail .video-content {
  padding: 10px 0;
}

.job-list li {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
}

.job-list li h3 {
  font-size: 16px;
}

.job-list li h3 a {
  color: #000;
}

.job-list li h3 a:hover {
  color: #5e8d25;
}

.job-list li .time {
  font-size: 14px;
}

.job-detail .title {
  border-bottom: 1px solid #f0f0f0;
  font-size: 18px;
  line-height: 32px;
  padding: 7px 0;
}

.job-detail .job-head {
  border-bottom: 1px solid #f0f0f0;
}

.job-detail .job-head span {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}

.job-detail .light {
  line-height: 40px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
}

.job-detail .cont {
  line-height: 26px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  padding: 7px 0;
}

.download-list li {
  position: relative;
  line-height: 34px;
  border-bottom: 1px solid #f0f0f0;
}

.download-list li h3 {
  font-size: 16px;
  margin: 0;
  line-height: 34px;
  padding-right: 110px;
}

.download-list li h3 a {
  color: #000;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.download-list li h3 a:hover {
  color: #5e8d25;
}

.download-list li .time {
  position: absolute;
  font-size: 14px;
  right: 0;
  top: 0;
}

.message-product img {
  width: 100%;
  height: auto;
  display: block;
  border: 1px solid #eee;
}

.message-product .message-product-title {
  font-size: 18px;
  color: #000;
  line-height: 36px;
}

.message-product .message-product-summary {
  font-size: 14px;
  line-height: 20px;
}

.message-product .message-product-href {
  background-color: #ff9966;
  color: #fff;
  font-size: 16px;
  border-radius: 3px;
  transition: all 0.3s ease 0s;
  padding: 0 10px;
  line-height: 34px;
  display: inline-block;
}

.message-product .message-product-href:hover {
  background: #ff982d;
}

.product-detail .product-download li {
  min-height: 40px;
  position: relative;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.product-detail .product-download li .iconfont {
  font-size: 40px;
  position: absolute;
  left: 0;
  top: 10px;
}

.product-detail .product-download li .cont {
  padding-left: 50px;
  line-height: 20px;
}

.product-detail .product-download li .cont p {
  margin: 0;
  font-size: 15px;
  color: #000;
}

.download-detail .title {
  font-size: 22px;
  line-height: 32px;
}

.download-detail .detail-title {
  font-size: 16px;
  border-bottom: 1px solid #f0f0f0;
  line-height: 40px;
}

.download-detail .download-content {
  line-height: 24px;
  padding: 10px 0;
}

.download-detail .download-btn {
  text-align: center;
  line-height: 34px;
}

.download-detail .download-btn .download-icon .iconfont {
  font-size: 40px;
}

.download-detail .download-btn a:hover {
  color: #5e8d25;
}

/* index ui style end */
/*inner page style*/
#page-wrap {
  padding-bottom: 30px;
}

#anchor {
  padding: 18px 0;
  line-height: 24px;
  background-color: #f5f6f9;
  font-size: 16px;
  color: #666;
  font-family: "Arial";
}

#anchor .anchor {
  color: #666;
}

#anchor .anchor a {
  color: #666;
}

#anchor .anchor a:visited {
  text-decoration: none;
  color: #666;
}

#anchor .anchor a:hover {
  text-decoration: none;
  color: #41ad3d;
}

.detail-title {
  border-bottom: 1px solid #f0f0f0;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0;
}

.left-title {
  font-weight: bold;
  color: #333;
  font-size: 26px;
  padding: 15px 0;
}

.cate-list {
  overflow: hidden;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: none;
}

.cate-list .list-title {
  line-height: 60px;
  display: block;
  padding-left: 15px;
  background: url(../images/arrow_cate02.jpg) no-repeat 95% 50%;
  color: #444;
  font-weight: bold;
  font-size: 18px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  transition: all 0.3s ease 0s;
  padding-right: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cate-list .list-title.arrow-click {
  background: url(../images/arrow_cate01.jpg) no-repeat 95% 50%;
}

.cate-list .list-title.arrow-03 {
  background: url(../images/arrow_cate03.jpg) no-repeat 95% 50%;
}

.cate-list .list-title:hover {
  color: #41ad3d;
}

.cate-list .map-category-list {
  display: none;
  padding: 10px;
  line-height: 24px;
}

.cate-list .map-category-list.show {
  display: block;
}

.cate-list .active {
  color: #41ad3d;
}

.right .content {
  border: 1px solid #f0f0f0;
  padding: 10px;
  line-height: 22px;
  font-size: 14px;
}

.right .content img {
  max-width: 100% !important;
  height: auto !important;
}

.right .content.no-border {
  border: none;
}

.news-lists {
  overflow: hidden;
}

.news-lists li {
  border: 1px solid #f0f0f0;
  padding: 15px 25px;
}

.news-lists li h3 {
  font-size: 22px;
}

.news-lists li h3 a {
  color: #000;
  transition: all 0.3s ease 0s;
}

.news-lists li h3 a:visited {
  color: #000;
  text-decoration: none;
}

.news-lists li h3 a:hover {
  text-decoration: none;
  color: #41ad3d;
}

.news-lists li .time {
  font-size: 13px;
  color: #333;
}

.news-lists li .news-abstract {
  font-size: 14px;
  color: #333;
  line-height: 24px;
}

.news-detail {
  border: 1px solid #f0f0f0;
  padding: 15px;
}

.news-detail .title {
  border-bottom: 1px solid #f0f0f0;
  font-size: 22px;
  color: #000;
}

.news-detail .title .time-text {
  padding: 10px 0;
  font-size: 14px;
  color: #333;
}

.edge {
  height: 1px;
  background: #f0f0f0;
}

.news-list-relat {
  overflow: hidden;
}

.news-list-relat li {
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-list-relat li a {
  transition: all 0.3s ease 0s;
  color: #333;
  font-size: 14px;
  display: inline-block;
  line-height: 24px;
}

.news-list-relat li a:visited {
  text-decoration: none;
  color: #333;
}

.news-list-relat li a:hover {
  text-decoration: none;
  color: #41ad3d;
}

.news-list-relat li .time {
  display: inline-block;
  line-height: 24px;
}

.tags-list-detail {
  border-bottom: 1px dashed #f0f0f0;
  padding: 10px 0;
}

.tags-list-detail a {
  padding-right: 5px;
}

#page-next-prev {
  padding-top: 6px;
}

#page-next-prev li {
  padding: 6px 0;
}

.product-list .title {
  line-height: 130px;
  color: #000;
  font-size: 40px;
}

.product-list .content .list {
  border: 1px solid #e1e6ea;
  background: #fff;
  margin: 10px 0;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.product-list .content .list .img {
  position: relative;
  display: block;
}

.product-list .content .list .img img {
  width: 100%;
}

.product-list .content .list .img .left-tip {
  position: absolute;
  left: 10px;
  top: 6px;
  display: inline-block;
  width: 50px;
  line-height: 28px;
  color: #FFF;
  background: #5e8d25;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.product-list .content .list .cont {
  position: relative;
  height: 100px;
}

.product-list .content .list .cont .product-title {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96%;
  line-height: 40px;
  position: absolute;
  left: 2%;
  top: 30px;
  font-size: 18px;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.product-list .content .list .cont .more {
  width: 132px;
  line-height: 32px;
  position: absolute;
  left: 50%;
  margin-left: -62px;
  top: 20px;
  display: inline-block;
  background: #5e8d25;
  color: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.product-list .content .list .cont .more:hover {
  background: #6db319;
}

.product-list .content .list:hover {
  box-shadow: 3px 3px 15px #cfcfcf;
}

.product-list .content .list:hover .product-title {
  top: 8px;
}

.product-list .content .list:hover .more {
  top: 50px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.case-list .title {
  line-height: 130px;
  color: #000;
  font-size: 40px;
}

.case-list .content .list {
  border: 1px solid #e1e6ea;
  background: #fff;
  margin: 10px 0;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.case-list .content .list .img {
  position: relative;
  display: block;
}

.case-list .content .list .img img {
  width: 100%;
}

.case-list .content .list .img .left-tip {
  position: absolute;
  left: 10px;
  top: 6px;
  display: inline-block;
  width: 50px;
  line-height: 28px;
  color: #FFF;
  background: #5e8d25;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.case-list .content .list .cont {
  position: relative;
  height: 100px;
}

.case-list .content .list .cont .case-title {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96%;
  line-height: 40px;
  position: absolute;
  left: 2%;
  top: 30px;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.case-list .content .list .cont .more {
  width: 132px;
  line-height: 32px;
  position: absolute;
  left: 50%;
  margin-left: -62px;
  top: 20px;
  display: inline-block;
  background: #5e8d25;
  color: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.case-list .content .list .cont .more:hover {
  background: #6db319;
}

.case-list .content .list:hover {
  box-shadow: 3px 3px 15px #cfcfcf;
}

.case-list .content .list:hover .case-title {
  top: 8px;
}

.case-list .content .list:hover .more {
  top: 50px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.case-detail {
  overflow: hidden;
}

.case-detail .title {
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
  font-size: 26px;
  margin: 0;
  padding: 15px 0;
  font-weight: bold;
  color: #666;
}

.case-detail .case-imgs {
  overflow: hidden;
}

.case-detail .case-imgs a.auto {
  overflow: hidden;
  text-align: center;
  width: auto;
}

.case-detail .case-imgs a.auto .zoomPad {
  float: none;
}

.page-content {
  border-top: 1px dashed #f0f0f0;
}

.page-content .page-left {
  display: inline-block;
  padding-top: 24px;
}

.jqzoom {
  text-decoration: none;
  display: block;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  margin-bottom: 8px;
}

.jqzoom.auto {
  height: auto !important;
}

.jqzoom .small {
  width: auto;
  height: 400px;
}

.detail-list-item h1 {
  font-size: 26px;
  color: #000;
}

.detail-list-item .abstract {
  line-height: 24px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
}

.detail-list-item li {
  padding: 10px 0;
  font-size: 16px;
}

.detail-list-item li .dt {
  display: inline;
  font-weight: bold;
  color: #000;
}

.detail-list-item li .dd {
  display: inline;
}

.inquiry {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 50px;
  color: #fff;
  background: linear-gradient(180deg, #383838 0, #000000);
  border-radius: 3px;
  font-size: 16px;
  transition: all 0.3s ease 0s;
}

.inquiry:visited {
  color: #fff;
}

.inquiry:hover {
  color: #fff;
  background: linear-gradient(180deg, #f90 0, #dc8503);
}

.tab-pane {
  padding: 15px 10px;
  line-height: 22px;
  font-size: 14px;
}

.nav-tabs {
  border-color: #ddd;
}

.nav-tabs li {
  margin-left: -1px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  z-index: 1;
}

.nav-tabs li a {
  color: #666;
  border-left: 0px;
  border-right: 0px;
  margin-right: 0px;
  padding: 10px 16px;
  background: #FBFAF8;
  border-bottom: 0px;
  border-radius: 0;
  font-weight: bold;
  font-size: 16px;
}

.nav-tabs li a:focus {
  color: #666;
  border-left: 0px;
  border-right: 0px;
  margin-right: 0px;
  padding: 10px 16px;
  background: #FBFAF8;
  border-bottom: 0px;
}

.nav-tabs li a:hover {
  background-color: #FFF;
  color: #5e8d25;
}

.nav-tabs li.active {
  border-top: 0px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  z-index: 3;
}

.nav-tabs li.active a {
  border-top: 2px solid #5e8d25;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #FFF;
  color: #5e8d25;
}

.nav-tabs li.active a:hover, .nav-tabs li.active a:focus {
  border-top: 2px solid #5e8d25;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #FFF;
  color: #5e8d25;
}

.no-information {
  padding: 20px 0;
}

.search-menu {
  border: 1px solid #e5e5e5;
}

.search-menu li {
  text-align: center;
  line-height: 34px;
}

.search-menu li a {
  color: #333;
}

.search-menu .on a {
  color: #41ad3d;
}

.search-list {
  padding-top: 10px;
}

.search-list li {
  border-bottom: 1px dotted #e5e5e5;
  position: relative;
  padding: 0;
  margin: 0;
}

.search-list li h3 {
  margin: 0;
  line-height: 36px;
}

.search-list li h3 a {
  font-size: 14px;
  color: #333;
}

.search-list li h3 a b {
  font-weight: normal;
  padding-right: 5px;
}

.search-list li h3 a:hover {
  color: #41ad3d;
}

.search-list li .time {
  position: absolute;
  right: 0;
  top: 13px;
}

.search-img-list {
  padding-top: 10px;
}

.search-img-list li {
  margin-bottom: 12px;
}

.search-img-list li .img img {
  width: 100%;
  height: 100%;
}

.search-img-list li h3 {
  text-align: center;
  margin: 0;
  line-height: 34px;
}

.search-img-list li h3 a {
  font-size: 13px;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.search-img-list li h3 a:hover {
  color: #41ad3d;
}

.sitemp-content {
  padding: 10px 0 20px;
}

.sitemp-content .map-title {
  line-height: 34px;
  background-color: #f5f8fa;
  border: 1px solid #d6dbe1;
  text-indent: 20px;
  color: #666;
  border-bottom: 2px solid #cccccc;
  font-size: 14px;
}

.sitemp-content .map-list a {
  padding-right: 8px;
}

.sitemp-content .map-list .map-product-cate-cname {
  font-size: 15px;
  color: #666;
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 15px;
}

.sitemp-content .map-list .map-product-cate-cname a {
  color: #666;
}

.sitemp-content .map-list .map-product-cate-cname a:visited {
  text-decoration: none;
  color: #666;
}

.sitemp-content .map-list .map-product-cate-cname a:hover {
  text-decoration: none;
  color: #1890ff;
}

.sitemp-content .map-list .map-product-list li {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sitemp-content .map-list .map-product-list li a {
  font-size: 14px;
  color: #999;
}

.sitemp-content .map-list .map-product-list li a:visited {
  text-decoration: none;
  color: #999;
}

.sitemp-content .map-list .map-product-list li a:hover {
  text-decoration: none;
  color: #1890ff;
}

.sitemp-content .sitemp-about-us {
  font-size: 18px;
  font-weight: bold;
}

.sitemp-content .sitemp-about-content {
  border: 1px solid #d6dbe1;
  padding: 10px;
  line-height: 24px;
}

.tag-all a {
  background-color: #f2f2f2;
  color: #666;
  padding: 5px 8px;
  text-decoration: none;
  margin-right: 5px;
  margin-bottom: 10px;
  display: inline-block;
}

.tag-all a:hover {
  background-color: #ddd;
}

.tag-menu li {
  text-align: center;
}

.tag-menu li.active a {
  color: #1890ff;
  font-weight: bold;
}

.tag-item, .sitemp-item {
  padding: 15px 0;
  font-size: 18px;
  border-bottom: 1px solid #d6dbe1;
}

.tag-describe {
  padding: 15px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  line-height: 24px;
}

.tag-describe span {
  color: #d8012d;
  font-weight: bold;
}

.tag-view {
  line-height: 24px;
  font-size: 16px;
  color: #7b7b7b;
  font-family: Verdana, Geneva, sans-serif;
}

.tag-view a i {
  font-size: 18px;
  color: #9b6a99;
}

.tag-view a:hover i {
  color: #666;
}

.tag-view a.active i {
  color: #f90;
}

.tag-category span {
  font-weight: bold;
  color: #7b7b7b;
  font-size: 12px;
  font-family: Verdana, Geneva, sans-serif;
}

.tag-category a {
  display: inline-block;
  padding: 5px 6px;
  border: 1px solid #f0f0f0;
  margin: 0 6px 10px 6px;
  border-radius: 4px;
}

.ishide {
  display: none;
}

.tag-product-list {
  overflow: hidden;
}

.tag-product-list li {
  border-bottom: 1px dashed #d6dbe1;
  padding: 20px 0;
}

.tag-product-list li:last-child {
  border: none;
}

.tag-product-list li .img {
  display: block;
  overflow: hidden;
}

.tag-product-list li .img img {
  width: 100%;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid #e1e6ea;
  transition: all 0.3s ease 0s;
}

.tag-product-list li .img img:hover {
  border: 1px solid #3daf3e;
}

.tag-product-list li .tag-product-content .title {
  font-size: 20px;
  color: #555;
  font-weight: bold;
  transition: all 0.3s ease 0s;
  padding-bottom: 10px;
}

.tag-product-list li .tag-product-content .title:hover {
  color: #f90;
}

.tag-product-list li .tag-product-content .attribute {
  overflow: hidden;
  padding-bottom: 10px;
}

.tag-product-list li .tag-product-content .abstract div {
  border: 1px solid #f0f0f0;
  padding: 10px;
  line-height: 24px;
}

.tag-product-list li .tag-product-content .tag-link .send-inquiry {
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff6666;
  border-radius: 3px;
  transition: all 0.3s ease 0s;
}

.tag-product-list li .tag-product-content .tag-link .send-inquiry.detail {
  background-color: #ff9966;
}

.tag-product-list li .tag-product-content .tag-link .send-inquiry:hover {
  color: #fff;
  background-color: #f90;
}

.tag-statis {
  background-color: #f5f5f5;
  border: 1px solid #f0f0f0;
  padding: 10px 15px;
  margin-top: 10px;
}

.tag-statis span {
  color: #333;
}

.tag-title {
  text-align: center;
  font-size: 14px;
}

.attr-list {
  border-top: 1px solid #f0f0f0;
}

.attr-list .attr-list-content {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
  line-height: 24px;
  font-size: 16px;
}

.attr-list .attr-list-content .attr-title {
  display: inline-block;
}

#dialog-common .btn-primary {
  background-color: #63b75e;
  border-color: #63b75e;
}

#dialog-common .modal-body .fa-exclamation-circle {
  font-size: 34px;
  color: #e87518;
}

#dialog-common .modal-body .common {
  padding-top: 5px;
  font-size: 15px;
}

#dialog-common .modal-body .fa-check-circle {
  font-size: 34px;
  color: #33cc1d;
}

.tab-content .key-tag {
  color: #ff0000;
}

.tab-content .key-tag:visited {
  text-decoration: none;
  color: #ff0000;
}

.tab-content .key-tag:hover {
  text-decoration: none;
  color: #f90;
}

/*inner page style*/
