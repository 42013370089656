.service-view {
	position: absolute;
	right: 15px;
	top: 30px;
	z-index: 99999;
	.service-list {
		background: rgba(134, 190, 242, 0.2);
		padding: 6px 6px 2px;
		border-radius: 4px;
		li {
			margin-bottom: 5px;
			border-radius: 8px;
			padding: 5px;
			a {
				img {
					width: 40px;
					height: 40px;
				}
			}
			&.skype {
				background: #00adec;
			}
			&.whatsapp {
				background: #179d1a;
			}
			&.line {
				background: #00b906;
			}
			&.email {
				background: #f26635;
			}
			&.message {
				background: #ad88be;
			}
			&:hover {
				@include opacity(0.8);
			}
		}
	}
	.service-close {
		position: absolute;
		right: -6px;
		top: -15px;
		width: 20px;
		height: 20px;
		background: #fff;
		color: #222;
		border-radius: 10px;
		line-height: 18px;
		border: 1px solid #ddd;
		font-family: "Arial";
		z-index: 99;
		cursor: pointer;
	}
}